import React from 'react';
import { Container, ImageContainer } from './style';
import LinkButton from 'components/link-button';

import { MobileHidden, MobileOnly } from 'utils/breakpoints';

// svgs
import NotFoundSVG from './error-404.svg';

const NotFound = () => {
  const homeLink = `https://www.leadhome.co.za/`;

  const message = `Oops, something went wrong. We've reported the issue and will get on it right away.`;

  const contactUsLink = 'https://www.leadhome.co.za/contact-us';

  return (
    <Container>
      <ImageContainer>
        <NotFoundSVG alt='404 Not Found' title='404 Not Found' />
      </ImageContainer>
      <MobileHidden>
        <p>
          {message}
          <br />
          Please try again or contact us on +27 (0)10 590 3088 to complete your
          booking.
        </p>
      </MobileHidden>
      <MobileOnly>
        <p>
          {message} Please try again or contact us to complete your booking.
        </p>
      </MobileOnly>
      <LinkButton href={homeLink}>Try again</LinkButton>
      <MobileOnly>
        <LinkButton color='secondary' href={contactUsLink}>
          Contact us
        </LinkButton>
      </MobileOnly>
    </Container>
  );
};

export default NotFound;
