import React from 'react';
import NotFound from 'components/not-found';
import Seo from '../components/seo';
import DefaultLayout from '@oxygen/ab/DefaultLayout';

const NotFoundPage = () => (
  <DefaultLayout fluid>
    <Seo
      title='404 Not Found'
      description='We could not find what you were looking for.'
    />
    <NotFound />
  </DefaultLayout>
);

export default NotFoundPage;
