import styled from 'styled-components';
import { colors, breakpoints } from '@oxygen/theme';

export const Container = styled.div`
  color: ${colors.white};
  background-color: ${colors.storm2};
  height: 100vh;
  margin-bottom: -20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  text-align: center;
  align-items: center;

  a {
    margin: 0 0 1rem;
    width: 100%;
  }

  @media (min-width: ${breakpoints.small}) {
    a {
      width: auto;
    }
  }
`;

export const ImageContainer = styled.div`
  padding: 2rem;
  svg {
    max-width: 100%;
  }
`;
