import styled from 'styled-components';

const breakpoints = {
  xs: '320px',
  small: '768px',
  medium: '1024px',
  large: '1280px'
};

export default breakpoints;

export const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${breakpoints.small}) {
    display: block;
    width: 100%;
  }
`;

export const TabletOnly = styled.div`
  display: none;
  @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium}) {
    display: block;
  }
`;

export const DesktopOnly = styled.div`
  display: none;
  @media (min-width: ${breakpoints.medium}) {
    display: block;
  }
`;

export const MobileHidden = styled.div`
  display: block;
  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const TabletHidden = styled.div`
  display: block;
  @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const DesktopHidden = styled.div`
  display: block;
  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;
